<template>
  <div class="row">
    <div class="col-12 g">
        <div class="card">
            <div class="card-header">
                <h5>
                    المهام <button class="btn btn-success" v-b-modal.create style="width: 30px; border-radius: 50%; padding: 0px; height: 30px">
                     <i class="fa fa-plus"></i>
                    </button>
                </h5>
            </div>
            <div class="card-body">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-bordered table-sm">
                        <thead>
                            <th>المهمة</th>
                            <th>تاريخ الانشاء</th>
                            <th>الموعد الاقصى</th>
                            <th>
                                الحالة
                            </th>
                            <th>
                                سجل النشاط
                            </th>
                            <th>خيارات</th>
                        </thead>
                        <tbody>
                            <tr v-for="u in todos" :key="u._id">
                                <td>
                                    <span v-if="u.from_admin" class="btn btn-sm btn-primary">
                                        <i class="fa fa-star" style="color: gold"></i> من الادارة 
                                    </span>
                                    {{ u.title }}
                                </td>
                                <td>
                                    {{ u.date }}
                                </td>
                                <td>
                                    {{ u.enddate }}
                                </td>
                                <td>
                                    <span v-if="u.status == 0" class="btn btn-sm btn-warning">
                                        <i class="fa fa-clock-o"></i>
                                        في الانتظار
                                    </span>
                                    <span v-if="u.status == 1" class="btn btn-sm btn-success">
                                        <i class="fa fa-check"></i>
                                        تم بنجاح
                                    </span>
                                    <span v-if="u.status == 2" class="btn btn-sm btn-danger">
                                        <i class="fa fa-times"></i>
                                        ملغية
                                    </span>
                                </td>
                                <td>
                                    <button class="btn btn-sm btn-primary" v-b-modal.logs @click="logs = u.logs; current = u._id">
                                        <i class="fa fa-list"></i>
                                        عرض ({{ u.logs.length }})
                                    </button>
                                </td>
                                <td>
                                    <b-dropdown size="sm" text="خيارات">
                                        <b-dropdown-item @click="details = u.details" v-b-modal.details>
                                            <i class="fa fa-list"></i>
                                            عرض
                                            التفاصيل
                                        </b-dropdown-item>
                                        <hr>
                                        <b-dropdown-item class="text-success" @click="current = u._id; status(1)" v-if="u.status == 0">
                                            <i class="fa fa-check"></i>
                                            تم بنجاح
                                        </b-dropdown-item>
                                        <b-dropdown-item class="text-danger" @click="current = u._id; status(2)" v-if="u.status == 0">
                                            <i class="fa fa-times"></i>
                                            الغاء
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="details" title="التفاصيل" hide-footer>
        <textarea v-model="details" readonly style="width: 100%" class="form-control bg-white" rows="10"></textarea>
    </b-modal>
    <b-modal id="create" title="اضافة مهمة" hide-footer>
        <div class="form-group">
          <label for="">المهمة</label>
          <input type="text"
            class="form-control" v-model="create.title">
        </div>
        <div class="form-group">
          <label for="">التفاصيل</label>
          <textarea class="form-control" v-model="create.details" rows="3"></textarea>
        </div>
        <div class="form-group">
          <label for="">الموعد الاقصى (اختياري)</label>
          <input type="date"
            class="form-control" v-model="create.enddate">
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-success" @click="createNow()">
                <i class="fa fa-check"></i>
                اضافة المهمة
            </button>
        </div>
    </b-modal>
    <b-modal id="logs" title="سجل النشاط" hide-footer>
        <div class="col-12 table-responsive">
        <div class="g">
            <div class="input-group mbx-3">
            <input type="text" class="form-control" v-model="logg" placeholder="اضافة نشاط..." aria-describedby="basic-addon2">
            <div class="input-group-append">
                <button class="input-group-text btn-primary" @click="addlog()" id="basic-addon2">اضافة</button>
            </div>
            </div>
        </div>
            <table class="table table-sm table-hover table-bordered">
                <thead>
                    <th>
                        التاريخ
                    </th>
                    <th>
                        التفاصيل
                    </th>
                    <th>
                        بواسطة
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(l, i) in logs" :key="i">
                        <td>
                            {{ l.date }}
                        </td>
                        <td>
                            {{ l.content }}
                        </td>
                        <td>
                            {{ l.from }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { VBModal, BModal, BFormInput, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
    components: {
        // Layouts
        BDropdown, BDropdownItem,
        BModal,BFormInput},
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            todos: [],
            create: {},
            logs: [],
            details: "",
            current: "",
            logg: ""
        }
    },
    created(){
        this.getR()
    },
    methods: {
        status(s){
            if(confirm("متأكد من تعيين المهمة الي " + (s==1 ? 'تم بنجاح' : 'ملغي') + "؟")){
                var g = this;
                $.post(api + '/reseller/todo/status', {
                    jwt: this.user.jwt,
                    id: g.current,
                    status: s
                }).then(function(r){
                    g.getR()
                })
            }
        },
        addlog(){
            var g = this;
            if(g.logg == "" || !g.logg){
                return "";
            }
            $.post(api + '/reseller/todo/log', {
                jwt: this.user.jwt,
                id: this.current,
                log: this.logg
            }).then(function(r){
                g.getR()
                $("#logs___BV_modal_header_ > button").click()
            })
        },
        getR(){
            var g = this;
            $.post(api + '/reseller/todo/list', {
                jwt: this.user.jwt
            }).then(function(r){
                g.todos = r.response;
            })
        },
        createNow(){
            var g = this;
            $.post(api + '/reseller/todo/create', {
                 jwt: this.user.jwt,
                 create: this.create
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert("تم انشاء المهمة بنجاح", 100)
                    g.getR()
                    $("#create___BV_modal_header_ > button").click()
                }
            }).fail(function(){
                alert("حدث مشكلة في الاتصال", 200)
            })
        }
    }
}
</script>

<style>

</style>